import { apiSlice } from '../apiSlice';

const integrationsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET /integrations
    getIntegrations: builder.query({
      query: () => ({
        url: `/integrations`,
        method: 'GET',
      }),
    }),
    // GET /integrations/user
    getIntegrationsForUser: builder.query({
      query: () => ({
        url: `/integrations/user`,
        method: 'GET',
      }),
    }),
    // GET /integrations/customer/customerId
    getIntegrationsForCustomer: builder.query({
      query: ({ customerId }) => ({
        url: `/integrations/customer/${customerId}`,
        method: 'GET',
      }),
      providesTags: (result, error, { customerId }) => [
        { type: 'CustomerIntegrations', id: customerId }
      ],
    }),
    // GET /integrations/customer
    getCustomerIntegrationsForUser: builder.query({
      query: () => ({
        url: '/integrations/customer',
        method: 'GET',
      }),
    }),
    // POST /integrations/{integrationId}/authenticationurl
    getIntegrationAuthenticationUrl: builder.query({
      query: ({ integrationId, payload }) => ({
        url: `/integrations/${integrationId}/authenticationurl`,
        method: 'POST',
        body: JSON.stringify(payload),
      }),
    }),
    // POST /integrations/{integrationId}/query
    postSearch: builder.query({
      query: ({ queryField, queryText, integrationId }) => ({
        url: `/integrations/${integrationId}/query`,
        method: 'POST',
        body: JSON.stringify({ queryField, queryText }),
      }),
    }),
    // GET /integrations/{integrationId}/demand/{matterId}/documents
    getDocumentsForMatter: builder.query({
      query: ({ matterId, integrationId }) => ({
        url: `/integrations/${integrationId}/demand/${matterId}/documents`,
        method: 'GET',
      }),
    }),
    postSaveIntegration: builder.mutation({
      query: ({ integrationId, fieldInfo, customerId }) => ({
        url: `/integration/${integrationId}/${customerId}`,
        method: 'POST',
        body: JSON.stringify(fieldInfo),
      }),
      invalidatesTags: (result, error, { customerId }) => [
        { type: 'CustomerIntegrations', id: customerId }
      ],
    }),
  }),
});


export const {
  useGetIntegrationsQuery,
  useGetIntegrationsForUserQuery,
  useGetIntegrationsForCustomerQuery,
  useGetCustomerIntegrationsForUserQuery,
  useGetIntegrationAuthenticationUrlQuery,
  useLazyGetIntegrationAuthenticationUrlQuery,
  useGetDocumentsForMatterQuery,
  useLazyPostSearchQuery,
  usePostSaveIntegrationMutation,
} = integrationsApi;